import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { ReStopListReducer } from './reducer';
import { ReStopListEffects } from './effect';
@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('ReStopList', ReStopListReducer),
    EffectsModule.forFeature([ReStopListEffects])
  ],
  exports: [StoreModule, EffectsModule],
  providers: []
})
export class ReStopListStoreModule {}
