import * as ClubAction from './action';
import { ClubAdapter, orderInitialState, ClubState } from './state';

export function ClubReducer(state: ClubState = orderInitialState, action: ClubAction.Actions) {
  switch (action.type) {
    case ClubAction.ADD_Club:
      // let data:any = action.payload;
      // data.forEach(element => {
      //     var obj =element;
      //     Object.assign(obj, {date: new Date(element.Date)});
      // });
      return {
        ...ClubAdapter.addOne(action.payload, state)
      };
    case ClubAction.REMOVE_Club:
      return {
        ...ClubAdapter.removeAll(state),
        isLoading: false,
        error: null
      };
    case ClubAction.UPDATE_Club:
      
//console.log(action.payload);
      return {
        ...ClubAdapter.updateOne({id: action.payload['id'], changes: action.payload }, state)
      };
    case ClubAction.POST_Club:
        return {
          ...ClubAdapter.removeAll(state),
          isLoading: false,
          error: null
        };
    default:
        return state;
  }
}
