import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { StoreRouterConnectingModule, routerReducer } from '@ngrx/router-store';
import { CustomSerializer } from './custom-route-serializer';

import { FareStoreModule } from './fare';
import { PnrStoreModule } from './pnr';
import { StopListStoreModule } from './stop';
import { PlanListStoreModule } from './plans';
import { ReStopListStoreModule } from './re-stop';
import { ClubStoreModule } from './club';

@NgModule({
  imports: [
    CommonModule,
    FareStoreModule,PnrStoreModule,StopListStoreModule,PlanListStoreModule,ReStopListStoreModule,ClubStoreModule,
    StoreModule.forRoot({}),
    EffectsModule.forRoot([]),
    StoreDevtoolsModule.instrument({
      maxAge: 25 // Retains last 25 states
    }),
    StoreRouterConnectingModule.forRoot({ serializer: CustomSerializer}),
  ],
  declarations: []
})
export class RootStoreModule {}
