import * as FareAction from './action';
import { FareAdapter, orderInitialState, FareState } from './state';

export function FareReducer(state: FareState = orderInitialState, action: FareAction.Actions) {
  switch (action.type) {
    case FareAction.ADD_Fare:
      // let data:any = action.payload;
      // data.forEach(element => {
      //     var obj =element;
      //     Object.assign(obj, {date: new Date(element.Date)});
      // });
      return {
        ...FareAdapter.addOne(action.payload, state)
      };
    case FareAction.REMOVE_Fare:
      return {
        ...FareAdapter.removeAll(state),
        isLoading: false,
        error: null
      };
    case FareAction.UPDATE_Fare:
      
//console.log(action.payload);
      return {
        ...FareAdapter.updateOne({id: action.payload['id'], changes: action.payload }, state)
      };
    case FareAction.POST_Fare:
        return {
          ...FareAdapter.removeAll(state),
          isLoading: false,
          error: null
        };
    default:
        return state;
  }
}
