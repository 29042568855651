import { Component, HostListener, OnInit } from '@angular/core';
import { MatSidenav } from '@angular/material';
import { Router } from '@angular/router';
import { CommonService, SharedserviceService } from 'src/app/services';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrManager } from 'ng6-toastr-notifications';
declare var $: any;


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  // showLeftBar:boolean=false;
  // user_id:any;
  // role_id:any;
  // user:any;

  // constructor(private router:Router) { 
  //   this.user = JSON.parse(localStorage.getItem("user"));
  //   
//console.log("user ::::::ASD:::::",this.user)
  //   if(this.user != null){ 
  //     this.user_id = this.user.id;
  //     this.role_id = this.user.role_id;
  //   }
  // }
  

  // ngOnInit() {
  // }
  // logout() {
  //   localStorage.removeItem("token");
  //   localStorage.removeItem("user");
  //   this.router.navigateByUrl('/')
  // }

  formEmail:FormGroup;
  userdata:any;
  user:any;
  user_type:any;
  name:any;
  ownerData: any;
  id:any;
  showlogin:boolean=true;
  submitted:boolean=false;
  parseuser:any;
  myContactData: any;
  getData: any;
  constructor(
    public commonService:CommonService,
    private router: Router, 
    public ngxloader: NgxUiLoaderService,
    private fb: FormBuilder, 
    public sharedService: SharedserviceService,
    private toastr: ToastrManager,
    ) {
    this.userdata= JSON.parse(localStorage.getItem("userAgency"));
    this.ownerData = JSON.parse(sessionStorage.getItem('ownerData'));
  }

  ngOnInit() { 
    this.contactDetail()
    this.user=JSON.parse(localStorage.getItem("user"));
    if(this.user){
      this.id = this.user.id;
      this.user_type = this.user.role_id;
      
//console.log("usertype",this.user_type);
      this.showlogin=false;
      this.name = this.user.name;
    }
    this.formEmail = this.fb.group({
      email: ['', Validators.required],
      name: ['', Validators.required],
      subject: ['', Validators.required],
      message: ['',Validators.required],
      mobile_no: ['', Validators.required],
      company_name: ['Company Name'],
    });


    this.commonService.onWebsiteSettings();
  }
  get enq1() { return this.formEmail.controls; }
  
  
  contactDetail() {
    const self = this;
    self.sharedService.get('v1/users/get_website_owner_data').subscribe((res: any) => {
      if (res.replyCode == "success") {
        this.getData = res.data;
        console.log("::::::::::::::",this.getData);
        sessionStorage.setItem("ownerData", JSON.stringify(this.getData) );
      } else {
        
      }
    });
  }

  enquiryForm(): void{
    this.submitted = true;
    if(this.formEmail.invalid){
      return
    }else{
    // var commentdata={
    //   "sector": this.formEmail.value.sector,
    //   "date": this.formEmail.value.date,
    //   "no_pax": this.formEmail.value.no_pax,
    //   "expected_fare": this.formEmail.value.expected_fare
    // }
    var requestData ={
      "name":this.formEmail.value.name,
      "email":this.formEmail.value.email,
      "subject": this.formEmail.value.subject,
      // "message": this.formEmail.value.message+" "+JSON.stringify(commentdata),
      "message": this.formEmail.value.message,
      "company_name": this.formEmail.value.company_name,
      "mobile_no": this.formEmail.value.mobile_no.toString(),
      "send_to_email":this.getData.email
      // "send_to_email":"sunil.fareboutique@gmail.com"
    }
  
    this.sharedService.post('v1/pages/contact_us',requestData).subscribe((res: any) => {
      if (res.replyCode == "success") {
        $('#enquiryFormNew').modal('hide');
        this.formEmail.reset();
        this.myContactData = res.data;
        // console.log("::::::::::::::",this.myContactData);
        this.toastr.successToastr(res.replyMsg, 'Success');
        this.submitted = false;
      } else {
        this.toastr.errorToastr(res.message, 'Error');
      }
    });
    }
  }
 
  enquiryModal(){
    $('#enquiryFormNew').modal('show');
  }

  profile(id){
    this.router.navigateByUrl("/dashboard/viewprofile");
   
  }
  logout() {
    this.showlogin=true;
    this.commonService.onLogout();
  }
  closeContact() {
    $('#enquiryFormNew').modal('hide');
  }

}
