import {Action} from '@ngrx/store';
import {Club} from '../../modal';

export const ADD_Club = '[Club] Add';
export const REMOVE_Club = '[Club] Remove';
export const UPDATE_Club = '[Club] Update';
export const POST_Club = '[Club] Load Request';
export const POST_Club_SUCCESS = '[Club] Update Success';
export const ERROR = '[Club] Update Error';

export class AddClub implements Action {
  readonly type = ADD_Club;
  constructor(
    public payload: Club
  ) {}
}
export class RemoveClub implements Action {
  readonly type = REMOVE_Club;
  constructor(
    
  ) {}
}
export class UpdateClub implements Action {
  readonly type = UPDATE_Club;
  constructor(
    public payload: Club
  ) {}
}

export class PostClubRequest implements Action {
  readonly type = POST_Club;
  constructor(
    public payload: Club[]
  ) {}
}

export class PostClubSuccess implements Action {
  readonly type = POST_Club_SUCCESS;
  constructor(
    public payload: []
  ) {}
}

export class Error implements Action {
  readonly type = ERROR;
  constructor(
    public payload: Club
  ) {}
}

export type Actions = AddClub | RemoveClub | UpdateClub | PostClubRequest | PostClubSuccess | Error;
