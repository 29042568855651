
import { Pipe,NgModule, Component, PipeTransform } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";

@Pipe({name: 'replaceUnderscore'})
export class ReplaceUnderscorePipe implements PipeTransform {
  transform(value: string): string {
    return value? value.replace(/_/g, " ") : value;
  }
}
