import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { StopList } from '../../modal';

export interface StopListState extends EntityState<StopList> {
    isLoading: boolean;
    selectedStopListId: any;
    error: any;
}

export const StopListAdapter: EntityAdapter<StopList> = createEntityAdapter<StopList>({
  selectId: (editorder: StopList) => 'data',
  sortComparer: false,
});

export const orderInitialState: StopListState = StopListAdapter.getInitialState({
  isLoading: true,
  selectedStopListId: null,
  error: null
});

export const selectedStopListId = (state: StopListState) => state.entities;
export const selectIsLoading = (state: StopListState) => state.isLoading;
export const selectError = (state: StopListState) => state.error;
