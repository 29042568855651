import {Action} from '@ngrx/store';
import {StopList} from '../../modal';

export const ADD_StopList = '[StopList] Add';
export const REMOVE_StopList = '[StopList] Remove';
export const UPDATE_StopList = '[StopList] Update';
export const POST_StopList = '[StopList] Load Request';
export const POST_StopList_SUCCESS = '[StopList] Update Success';
export const ERROR = '[StopList] Update Error';

export class AddStopList implements Action {
  readonly type = ADD_StopList;
  constructor(
    public payload: StopList
  ) {}
}
export class RemoveStopList implements Action {
  readonly type = REMOVE_StopList;
  constructor(
    
  ) {}
}
export class UpdateStopList implements Action {
  readonly type = UPDATE_StopList;
  constructor(
    public payload: StopList
  ) {}
}

export class PostStopListRequest implements Action {
  readonly type = POST_StopList;
  constructor(
    public payload: StopList[]
  ) {}
}

export class PostStopListSuccess implements Action {
  readonly type = POST_StopList_SUCCESS;
  constructor(
    public payload: []
  ) {}
}

export class Error implements Action {
  readonly type = ERROR;
  constructor(
    public payload: StopList
  ) {}
}

export type Actions = AddStopList | RemoveStopList | UpdateStopList | PostStopListRequest | PostStopListSuccess | Error;
