import { Select2OptionData } from 'ng-select2';
import { Options } from 'select2';
import { AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {  HostListener, Output, EventEmitter, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { MustMatch } from 'src/app/services/must-match';
import { SharedserviceService } from 'src/app/services/sharedservice.service';
import { CommonService } from 'src/app/services/commonservice.service'
import { DatePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { from } from 'rxjs';
import { ToastrManager } from 'ng6-toastr-notifications';

import { MatSelect } from '@angular/material/select';
import { ReplaySubject, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { DateAdapter } from '@angular/material';
declare var jQuery:any;
declare var $:any;


@Component({
  selector: 'app-app-dashboard',
  templateUrl: './app-dashboard.component.html',
  styleUrls: ['./app-dashboard.component.scss']
})
export class AppDashboardComponent implements OnInit, AfterViewInit, OnDestroy {
  public options: Options;
  departure_city_data:Array<Select2OptionData>;
  public options1: Options;
  arrival_city_data:Array<Select2OptionData>;
  
  airport_city_data:any; 
  exampleData:any;
  public submitted=false;
  dropCity:boolean=true;
  searchForm:FormGroup;
  travellers:FormGroup;
  total:any;
  flight:any;
  // type:any = 'oneway';
  type:any;
  selecttype:any='Select By Airline';
  disableInput: boolean;
  return_date:string;
  searchnotresult:boolean=false;



  minDate:any;
  maxDate:any;
  arrivaldate_input:boolean=false;
  departure_date:boolean=false;
  return_date_input:boolean=false;
  traveller_input:boolean=false;
  departure_city_lists:any;
  arrival_city_lists:any;
  departure_city_ids:any;
  arrival_city_ids:any;
  d_date:any;
  flighttype:any='one_way';
  validDates:any;
  myFilter :any;
  myFilter1 :any;

    /** list of departure cities */
    Departurecity_DATA: departurecity_lists[]= [];
    protected dcitylist: departurecity_lists[];
      //protected  Departurecity_DATA: departurecity_lists[] = [];
    public departureCtrl: FormControl = new FormControl();
    /** control for the MatSelect filter keyword */
    public departureFilterCtrl: FormControl = new FormControl();
    /** list of banks filtered by search keyword */
    public filtered_departurecity: ReplaySubject<departurecity_lists[]> = new ReplaySubject<departurecity_lists[]>(1);
    @ViewChild('departureSelect', { static: true }) departureSelect: MatSelect;
    /** Subject that emits when the component has been destroyed. */
    protected _onDestroy = new Subject<void>();
   
   
   
    /** list of arrival cities */
    Arrivalcity_DATA: arrivalcity_lists[]= [];
    protected acitylist: arrivalcity_lists[];
      //protected  Arrivalcity_DATA: arrivalcity_lists[] = [];
    //public arrivalCtrl: FormControl = new FormControl();
    /** control for the MatSelect filter keyword */
    public arrivalFilterCtrl: FormControl = new FormControl();
    /** list of banks filtered by search keyword */
    public filtered_arrivalcity: ReplaySubject<arrivalcity_lists[]> = new ReplaySubject<arrivalcity_lists[]>(1);
    @ViewChild('arrivalSelect', { static: true }) arrivalSelect: MatSelect;
    /** Subject that emits when the component has been destroyed. */
    protected _onDestroy1 = new Subject<void>();
    UserData:any;

  constructor(private dateAdapter: DateAdapter<Date>,private datePipe : DatePipe,private activatedRoute: ActivatedRoute, private fb: FormBuilder,public dataservice:CommonService, 
    public httpClient: SharedserviceService, public router: Router,private toastr: ToastrManager) {
    this.dateAdapter.setLocale('en-GB');

    
//console.log("this.dataservice.getUser()====",this.dataservice.getUser());

    if(this.dataservice.getUser() != undefined)
    {
     this.UserData = JSON.parse(this.dataservice.getUser());
    // 
//console.log('UserDAta=============',this.UserData);
      httpClient.auth_token=this.UserData.login_token
    }
  }

  initiate(){
    this.searchForm = this.fb.group({
      departure_city_id:['',[Validators.required]],
      //departure_city:['',[Validators.required]],
      departure_airport_id:['',[Validators.required]],
      arrival_city_id:['',[Validators.required]],
      //arrival_city:['',[Validators.required]],
      arrival_airport_id:['',[Validators.required]],
      trip_type:['0',[Validators.required]],
      departure_date:['',[Validators.required]],
      return_date:[''],
      adult:1,
      child:0,
      travellers:'1',
      infant:0
    }); 
    this.travellers = this.fb.group({
      adult:['1'],
      child:['0'],
      infant:['0'],
    });
    $('mat-select-search-input').attr('placeholder','departure city')
  }

  ngOnInit() {
    this.initiate()
    this.departure_city_list();
    // window.onload=function(){
    //   document.getElementById("linkid").click();
    // };
    //this.departure_city_list()
    this.disableInput = true;
    // listen for search field value changes
    // this.departureFilterCtrl.valueChanges
    //   .pipe(takeUntil(this._onDestroy)) 
    //   .subscribe(() => {
    //     this.filterDeparturecity();
    //   });
    //$('#returndate').prop("disabled", true);
    //$('#returndate').prop("disabled", false);    
}

searchsubmit(){
  const self=this;
  this.submitted = true;
  
//console.log("search form :::::",this.searchForm.value);
  let formdata=this.searchForm.value;
  if(formdata.departure_city_id != "" && formdata.departure_airport_id != '' && formdata.arrival_city_id != "" && formdata.arrival_airport_id != '')
  {
    
    var sendrequestdata={
      departure_city_id:formdata.departure_city_id,
      departure_airport_id:formdata.departure_airport_id,
      arrival_city_id:formdata.arrival_city_id,
      arrival_airport_id:formdata.arrival_airport_id,
      trip_type:formdata.trip_type,
      departure_date:formdata.departure_date,
      return_date:formdata.return_date,
      adult:formdata.adult,
      children:formdata.child,
      infant:formdata.infant
    }
    sessionStorage.setItem("search_form",JSON.stringify(this.searchForm.value));
    
    this.dataservice.searchflightdetail(sendrequestdata);
    
    
    this.httpClient.post('v1/search_flight/search_ticket',sendrequestdata).subscribe((res)=> {
    self.httpClient.loadingShow = false;
    if(res.replyCode == "error") {
      this.searchnotresult=true;
     }
    }); 
  }
  else{
    this.toastr.errorToastr('Fill The Form Correctly.');
  }
}

  departure_city_list(){
      const self=this;
      //this.httpClient.auth_token = this.user.jwt;
      self.httpClient.loadingShow = true;
      //self.regForm.patchValue({token:this.token});
      var sendrequestdata={
      flight_type:this.flighttype
      // flight_type:"one_way"
  }
    this.httpClient.post('v1/search_flight/departure_city_list',sendrequestdata).subscribe((res)=> {
    self.httpClient.loadingShow = false;
      if(res.replyCode == "success") { 
        this.departure_city_lists = res.data;
        var d_city=[];

        
//console.log('departure city list', this.departure_city_lists);
        self.departure_city_lists.forEach(element => {
        
          d_city.push({
            id:element.departure_city_id,
            text:element.departure_city_name,
            additional: {
              image: element.airport_code,
              winner: element.airport_name
            }
          }) 
        });
       
        this.departure_city_data=d_city;
        this.options = {
          templateResult: this.templateResult,
          templateSelection: this.templateSelection
        };
        }
    });
  }
  public templateResult = (state: Select2OptionData): JQuery | string => {
    if (!state.id) {
      return state.text;
    }
    // return jQuery('<span style="font-weight:500">' + state.text + ' </span><br/><span style="font-size:11px;">' + state.additional.winner + '</span><span style="font-size:12px;float:right;margin-top:-12px;font-weight:500">'+state.additional.image+'</span>');
    return jQuery('<span style="font-weight:500">' + state.text + ' </span><p style="margin:0px;font-size:10px;position:relative">' + state.additional.winner + '<span style="font-size:12px;float:right;right:3px;margin-top:-12px;font-weight:500;position:absolute;">'+state.additional.image+'</span></p>');
  }
  // function for selection template
  public templateSelection = (state: Select2OptionData): JQuery | string => {
    if (!state.id) {
      return state.text;
    }
    // return jQuery('<span>'+ state.text +'&nbsp;'+ state.additional.winner + '&nbsp; ('+state.additional.image +')</span>');
    return jQuery('<span>'+ state.text +'&nbsp; ('+state.additional.image +')</span>');
  }
  arrival_city_list(id){
   if(id){ 
    //var name=id.split('-')[0];
    
//console.log("departure city filter :::: ",this.departure_city_lists,"id ::::",id);
    var filterlist = this.departure_city_lists.filter(x => x.departure_city_id == id);
    // 
//console.log("arrivalfilter city :::: ",filterlist);
    this.departure_city_ids=filterlist[0].departure_city_id;
    this.searchForm.patchValue({departure_city_id:filterlist[0].departure_city_id,departure_airport_id:filterlist[0].airport_id})
    const self=this;
    self.httpClient.loadingShow = true;
    var sendrequestdata={
      flight_type:this.flighttype,
      departure_city_id:this.departure_city_ids
    }  
    this.httpClient.post('v1/search_flight/arrival_city_list',sendrequestdata).subscribe((res)=> {
    self.httpClient.loadingShow = false;
    if(res.replyCode == "success") { 
      
//console.log("hello ::: :: : : :");
        self.arrival_city_lists = res.data;
        
//console.log('arrival city list', self.arrival_city_lists);
        this.Arrivalcity_DATA.length=0;
        var a_city=[]
        self.arrival_city_lists.forEach(element => {
              a_city.push({
                id:element.arrival_city_id,
                  text:element.arrival_city_name,
                  additional: {
                    image: element.airport_code,
                    winner: element.airport_name
                  }
              })
        });
        this.arrival_city_data=a_city;

        this.options1 = {
          templateResult: this.templateResult,
          templateSelection: this.templateSelection
          };
        }
    });
    }
  }
  
  // arrival_cityid(id){
  //   if(id){
  //   //var name=id.split('-')[0];
  //   var filterlist = this.arrival_city_lists.filter(x => x.arrival_city_id == id);
  //   
//console.log("filter city :::: ",filterlist);
  //   this.arrival_city_ids=filterlist[0].arrival_city_id;
  //   this.searchForm.patchValue({arrival_city_id:filterlist[0].arrival_city_id,arrival_airport_id:filterlist[0].airport_id})
  //   const self=this;
  //   //this.httpClient.auth_token = this.user.jwt;
  //   self.httpClient.loadingShow = true;
  //   //self.regForm.patchValue({token:this.token});
  //   var is_rtn;
  //   if(this.flighttype == 'return'){
  //     is_rtn=1;
  //   }else{
  //     is_rtn=0;
  //   }
  //   var sendrequestdata={
  //     departure_city_id:this.departure_city_ids,
  //     arrival_city_id:this.arrival_city_ids,
  //     is_return:is_rtn
  //   }
  //   this.httpClient.post('v1/search_flight/booking_avaliable_date_list',sendrequestdata).subscribe((res)=> {
  //   self.httpClient.loadingShow = false;
  //   if(res.replyCode == "success") { 
  //       self.d_date = res.data;
  //       
//console.log('arrival city list', self.d_date);
  //       var date_length=res.data.length;
  //       
//console.log("array length ::::",date_length)
  //      // this.minDate=self.datePipe.transform(new Date(), 'yyyy-MM-dd')
  //      if(date_length > 0){
  //       this.minDate=self.d_date[0].flight_date
  //       this.maxDate=self.d_date[date_length-1].flight_date
  //       }
  //     }
  //   });
  //  }
  // }

  arrival_cityid(id){
    if(id){
      var filterlist = this.arrival_city_lists.filter(x => x.arrival_city_id == id);
      if(filterlist){
          
//console.log("filter city :::: ",filterlist);
          this.arrival_city_ids=filterlist[0].arrival_city_id;
          this.searchForm.patchValue({arrival_city_id:filterlist[0].arrival_city_id,arrival_airport_id:filterlist[0].airport_id})
          const self=this;
          self.httpClient.loadingShow = true;
          var is_rtn;
          if(this.flighttype == 'return'){
            is_rtn=1;
          }else{
            is_rtn=0;
          }
          var sendrequestdata={
            departure_city_id:this.departure_city_ids,
            arrival_city_id:this.arrival_city_ids,
            is_return:is_rtn
          }
          var validdates=[]
          this.httpClient.post('v1/search_flight/booking_avaliable_date_list',sendrequestdata).subscribe((res)=> {
          self.httpClient.loadingShow = false;
          if(res.replyCode == "success") { 
              self.d_date = res.data;
              
//console.log('arrival city list', self.d_date);
              var date_length=res.data.length;
              
//console.log("array length ::::",date_length)
            // this.minDate=self.datePipe.transform(new Date(), 'yyyy-MM-dd')
            if(date_length > 0){
              this.minDate=self.d_date[0].flight_date
              this.maxDate=self.d_date[date_length-1].flight_date
              }
              res.data.forEach(element => {
                validdates.push(new Date(this.datePipe.transform(element.flight_date, 'MM/dd/yyyy')))
              });
              this.myFilter1 = (d: Date): boolean => {
                const time=d.getTime();
                return validdates.find(x=>x.getTime()==time);

              } 
              
//console.log("myfilter2 :::::::::::::::",this.myFilter1) 
            }
          });
      }  
    }
  }

  changestatus(label){
    if(label == 'arrivaldate_input'){
      this.submitted=false;
      this.searchForm.patchValue({departure_city_id:'',departure_date:'',return_date:''});  
      this.arrivaldate_input=true
    }else if(label == 'departure_date'){
      this.submitted=false;
      this.searchForm.patchValue({departure_date:'',return_date:''});  
      this.departure_date=true
    }else if(label == 'return_date_input'){
      this.submitted=false;
      
//console.log("return date")
      this.return_date_input=true
    }else if(label == 'traveller_input'){
      this.submitted=false;
      this.traveller_input=true
    }
  }
  set_d_date(val){
    this.searchForm.patchValue({return_date:''});
    
//console.log("todays date",this.datePipe.transform(val, 'yyyy/MM/dd'));
    var currentdate=this.datePipe.transform(val, 'yyyy-MM-dd');
    this.searchForm.patchValue({departure_date:this.datePipe.transform(val, 'yyyy-MM-dd')});
    if(this.flighttype == 'return'){
        var sendrequestdata={
          departure_city_id:this.departure_city_ids,
          arrival_city_id:this.arrival_city_ids,
          departure_date:this.datePipe.transform(val, 'yyyy-MM-dd'),
          is_return:1
        }
        var validdates=[]
        this.httpClient.post('v1/search_flight/return_booking_avaliable_date_list',sendrequestdata).subscribe((res)=> {
        this.httpClient.loadingShow = false;
        if(res.replyCode == "success") { 
          
          res.data.forEach(element => {
            var nwdate =  new Date(currentdate);
            var newdate= nwdate.setDate(nwdate.getDate()+element.return_in_days);
            var return_d_date=this.datePipe.transform(newdate, 'MM/dd/yyyy');
            //var convdate=return_d_date.toString()+"T08:00:00"
            validdates.push(new Date(return_d_date))
          });
          
//console.log("return date :::::::",validdates)
         // this.validDates=validdates[0]
          this.myFilter = (d: Date): boolean => {
            const time=d.getTime();
            return validdates.find(x=>x.getTime()==time);
          }
          
        }
        });
    }


    // var nwdate =  new Date(newReturnTodate);
    // var newdate= nwdate.setDate(nwdate.getDate()+this.ReturnfirstFormGroup.value.trip_duration);
    // var return_d_date=this.datePipe.transform(newdate, 'yyyy-MM-dd');
  }
  set_r_date(val){
    
//console.log("return date",this.datePipe.transform(val, 'yyyy/MM/dd'));
    this.searchForm.patchValue({return_date:this.datePipe.transform(val, 'yyyy-MM-dd')});
  }

// 

  Change_Status(val){
    this.initiate()
    if(val=='oneway'){
      this.disableInput = true;
      this.return_date = '';
      this.flighttype='one_way';
      this.searchForm.patchValue({
        trip_type:'0',
        adult:1,
        child:0,
        travellers:1,
        infant:0
      });
      this.return_date_input=false;
      this.departure_city_list();
    }else{
      this.flighttype='return';
      this.disableInput = null;
      this.searchForm.patchValue({
        trip_type:'1',
        adult:1,
        child:0,
        travellers:1,
        infant:0
      });
      this.departure_city_list();
    }
    
//console.log("flight type",this.searchForm.value);
  }

  open_popup(){
    $('#totalpassengers').modal('show');
  }
  
  traveller(){
    var adult = this.travellers.value.adult;
    var child = this.travellers.value.child;
    var infant = this.travellers.value.infant;
   
    if(adult !='' && child !='' && infant !=''){
      if(parseInt(adult) >= parseInt(infant)){
        this.total = parseInt(adult) + parseInt(child) + parseInt(infant);
        
        this.searchForm.patchValue({
          adult:parseInt(adult),
          child:parseInt(child),
          infant:parseInt(infant)
        });
        
//console.log("formdata ::", this.searchForm);
        $('#totalpassengers').modal('hide');
        this.searchForm.patchValue({travellers:this.total});
      } else{
        alert('Please select infant less than adult');
      }
      
    }else{
      alert('All Field is required');
    }
      
    
  }

  searchData(){
    alert(JSON.stringify(this.searchForm.value));
    //var type = this.searchForm.value.type;
    const self=this;
    //this.httpClient.auth_token = this.user.jwt;
    self.httpClient.loadingShow = true;
    //self.regForm.patchValue({token:this.token});
     
    this.httpClient.post('searchFlights',this.searchForm.value).subscribe((res)=> {
    self.httpClient.loadingShow = false;
      if(res.success) { 
        localStorage.setItem("user_emailAgency", JSON.stringify(res.data));
        localStorage.setItem("type",'register');
        self.flight = res.data;
        
//console.log('flight Data', self.flight);
        $('#success').show();
          setTimeout(function(){
            $('#success').hide();
          }, 2000);
        }else{
          $('#error').show();
          setTimeout(function(){
            $('#error').hide();
          }, 4000);
      }
    });
  }


  ngAfterViewInit() {
    this.setInitialValue();
  }
  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();

    this._onDestroy1.next();
    this._onDestroy1.complete();
  }
  protected setInitialValue() {
    this.filtered_departurecity
      .pipe(take(1), takeUntil(this._onDestroy))
      .subscribe(() => {
        this.departureSelect.compareWith = (a: departurecity_lists, b: departurecity_lists) => a && b && a.id === b.id;
      });
  }
  protected filterDeparturecity() {
    if (!this.Departurecity_DATA) {
      return;
    }
    // get the search keyword
    let search = this.departureFilterCtrl.value;
    if (!search) {
      this.filtered_departurecity.next(this.Departurecity_DATA.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    this.filtered_departurecity.next(
      this.Departurecity_DATA.filter(a => a.departure_city_name.toLowerCase().indexOf(search) > -1)
    );
    
  //   // filter the banks
  //   this.filtered_departurecity.next(
  //   this.Departurecity_DATA.filter(Departurecity_DATA => Departurecity_DATA.departure_city.name.toLowerCase().indexOf(search) > -1)
  //    // this.Departurecity_DATA.filter(function(hero) {
  //    //   return hero.departure_city.name.toLowerCase().indexOf(search) > -1
  //    // })
  //   );
  //   var index =this.Departurecity_DATA.filter(Departurecity_DATA => Departurecity_DATA.departure_city.name.toLowerCase().indexOf(search))
  //   var iasd= this.Departurecity_DATA.indexOf(search,0)
  //   
//console.log("asasdawwd",iasd)
  //  }
  
}

  protected filterarrivalcity() {
    if (!this.Arrivalcity_DATA) {
      return;
    }
    // get the search keyword
    let search = this.arrivalFilterCtrl.value;
    if (!search) {
      this.filtered_arrivalcity.next(this.Arrivalcity_DATA.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    this.filtered_arrivalcity.next(
      this.Arrivalcity_DATA.filter(d_city => d_city.arrival_city_name.toLowerCase().indexOf(search) > -1)
    );
  }
}
export interface JQuery{

}
export interface  arrivalcity_lists{
  id: number;
  arrival_city_id: number;
  arrival_city_name: string;
  arrival_city_code: number;
  airport_name: string;
  airport_id: number;
}
export interface  departurecity_lists{
  id: number;
  departure_city_id: number;
  departure_city_name: string;
  departure_city_code: number;
  airport_name: string;
  airport_id: number;
  
}