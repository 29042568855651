import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Fare } from '../../modal';

export interface FareState extends EntityState<Fare> {
    isLoading: boolean;
    selectedFareId: any;
    error: any;
}

export const FareAdapter: EntityAdapter<Fare> = createEntityAdapter<Fare>({
  selectId: (editorder: Fare) => 'data',
  sortComparer: false,
});

export const orderInitialState: FareState = FareAdapter.getInitialState({
  isLoading: true,
  selectedFareId: null,
  error: null
});

export const selectedFareId = (state: FareState) => state.entities;
export const selectIsLoading = (state: FareState) => state.isLoading;
export const selectError = (state: FareState) => state.error;
