import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { CommonService } from './commonservice.service';

@Injectable({
	providedIn: 'root'
  })
  export class HomeGuardService implements CanActivate {
	constructor(
	  public auth: CommonService,
	  public router: Router
	) {}
	canActivate(): boolean {
	  if (!this.auth.getToken()) {
		this.router.navigateByUrl('/home');
		return true;
	  }
	  this.router.navigateByUrl('/dashboard');
	  
//console.log('TokenGuard-return::::',false);
	  return false;
	}
}

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {
  constructor(
    public auth: CommonService,
    public router: Router
  ) {}
  canActivate(): boolean {
    if (!this.auth.getToken()) {
      // 
//console.log(this.auth.getToken())
      this.router.navigateByUrl('/auth/login');
      return false;
    }
    return true;
  }
}

