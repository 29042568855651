import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StopListReducer } from './reducer';
import { StopListEffects } from './effect';
@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('StopList', StopListReducer),
    EffectsModule.forFeature([StopListEffects])
  ],
  exports: [StoreModule, EffectsModule],
  providers: []
})
export class StopListStoreModule {}
