import { Select2OptionData } from 'ng-select2';
import { Options } from 'select2';
import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { HostListener, Output, EventEmitter, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { MustMatch } from 'src/app/services/must-match';
import { SharedserviceService } from 'src/app/services/sharedservice.service';
import { CommonService } from 'src/app/services/commonservice.service'
import { DatePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { from } from 'rxjs';
import { ToastrManager } from 'ng6-toastr-notifications';

import { MatSelect } from '@angular/material/select';
import { ReplaySubject, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { DateAdapter } from '@angular/material';
// import { connectableObservableDescriptor } from 'metropolitan/node_modules/rxjs/internal/observable/ConnectableObservable';
declare var jQuery: any;
declare var $: any;
 
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  providers: [DatePipe]
})
export class DashboardComponent implements OnInit, AfterViewInit, OnDestroy {
  public options: Options;
  departure_city_data: Array<Select2OptionData>;
  date:any;
  returndate_min:any;
  b_city: any = [];
  d_city: any = [];
  a_city: any = [];
  ticketDtl: any;
  public options1: Options;
  arrival_city_data: Array<Select2OptionData>;
  return_d_date: any;
  rreturn_d_date: any;
  airport_city_data: any;
  exampleData: any;
  public submitted = false;
  dropCity: boolean = true;
  searchForm: FormGroup;
  travellers: FormGroup;
  total: any;
  flight: any;
  // type:any = 'oneway';
  type: any;
  readMore: boolean = false;
  selecttype: any = 'Select By Airline';
  disableInput: boolean;
  return_date: string;
  searchnotresult: boolean = false;
  searchdate: any = '';
  previous_day: any;
  to_day: any;
  next_day: any;
  imagesrc: any;
  searchresult: boolean = false;
  newCal: any;
  newCal2: any;
  newCal3: any;
  search_details: any;
  aval_seat: any;

  user: any = [];
  minDate: any;
  maxDate: any;  
  return_min_date:any;
  return_max_date:any;
  arrivaldate_input: boolean = false;
  departure_date: boolean = false;
  return_date_input: boolean = false;
  traveller_input: boolean = false;
  departure_city_lists: any;
  departureBlockList: any;
  arrival_city_lists: any;
  departure_city_ids: any;
  arrival_city_ids: any;
  d_date: any;
  flighttype: any = 'one_way';
  validDates: any;
  myFilter: any;
  myFilter1: any;
  flightdetails: any;
  flightBlockedDetails: any;
  showSelf: boolean = false;
  showSelfBlock: boolean = false;
  


  /** list of departure cities */
  Departurecity_DATA: departurecity_lists[] = [];
  protected dcitylist: departurecity_lists[];
  //protected  Departurecity_DATA: departurecity_lists[] = [];
  public departureCtrl: FormControl = new FormControl();
  /** control for the MatSelect filter keyword */
  public departureFilterCtrl: FormControl = new FormControl();
  /** list of banks filtered by search keyword */
  public filtered_departurecity: ReplaySubject<departurecity_lists[]> = new ReplaySubject<departurecity_lists[]>(1);
  @ViewChild('departureSelect', { static: true }) departureSelect: MatSelect;
  /** Subject that emits when the component has been destroyed. */
  protected _onDestroy = new Subject<void>();



  /** list of arrival cities */
  Arrivalcity_DATA: arrivalcity_lists[] = [];
  protected acitylist: arrivalcity_lists[];
  //protected  Arrivalcity_DATA: arrivalcity_lists[] = [];
  //public arrivalCtrl: FormControl = new FormControl();
  /** control for the MatSelect filter keyword */
  public arrivalFilterCtrl: FormControl = new FormControl();
  /** list of banks filtered by search keyword */
  public filtered_arrivalcity: ReplaySubject<arrivalcity_lists[]> = new ReplaySubject<arrivalcity_lists[]>(1);
  @ViewChild('arrivalSelect', { static: true }) arrivalSelect: MatSelect;
  /** Subject that emits when the component has been destroyed. */
  protected _onDestroy1 = new Subject<void>();
  UserData: any;
  ownerData: any;

  constructor(private dateAdapter: DateAdapter<Date>, private datePipe: DatePipe, private activatedRoute: ActivatedRoute, private fb: FormBuilder, public dataservice: CommonService,
    public httpClient: SharedserviceService, public router: Router, private toastr: ToastrManager,private changeRef: ChangeDetectorRef) {
    this.dateAdapter.setLocale('en-GB');
    this.user = JSON.parse(localStorage.getItem("userAgency"));
    this.ownerData= JSON.parse(sessionStorage.getItem("ownerData"));
    if (this.dataservice.getUser() != undefined) {
      this.UserData = JSON.parse(this.dataservice.getUser());
      httpClient.auth_token = this.UserData.login_token
    }
  }

  bookflight(flight) {
    const self = this;
    var formvalue = this.searchForm.value
    this.aval_seat = flight.total_available_seats
    var totalseat = Number(formvalue.adult) + Number(formvalue.child) + Number(formvalue.infant)
    if (this.aval_seat >= totalseat) {
      var senddata = {
        flight_id: flight.flight_id,
        trip_type: formvalue.trip_type,
        departure_date: formvalue.departure_date,
        return_date: formvalue.return_date,
        adult: formvalue.adult,
        children: formvalue.child,
        infant: formvalue.infant,
        inventory_user_Type: "self"
      }
      this.dataservice.searchedflightdetail(senddata);
    } else {
      $('#confirmbooking').modal('show');
    }
  }
  ngAfterViewChecked(){
    //your code to update the model
    this.changeRef.detectChanges();
  }

  initiate() {
    this.searchForm = this.fb.group({
      departure_city_id: ['', [Validators.required]],
      //departure_city:['',[Validators.required]],
      departure_airport_id: ['', [Validators.required]],
      arrival_city_id: ['', [Validators.required]],
      //arrival_city:['',[Validators.required]],
      arrival_airport_id: ['', [Validators.required]],
      trip_type: ['0', [Validators.required]],
      departure_date: ['', [Validators.required]],
      return_date: [''],
      adult: 1,
      child: 0,
      travellers: '1',
      infant: 0
    });
    this.travellers = this.fb.group({
      adult: ['1'],
      child: ['0'],
      infant: ['0'],
    });
    $('mat-select-search-input').attr('placeholder', 'departure city')
  }

  ngOnInit() {
    console.log("os",this.dataservice.osname);
    this.initiate()
    this.departure_city_list();
    this.disableInput = true;    
  }

  timeConvert(n) {
    var num = n;
    var hours = (num / 60);
    var rhours = Math.floor(hours);
    var minutes = (hours - rhours) * 60;
    var rminutes = Math.round(minutes);
    return rhours + " hr  " + rminutes + " min";
  }

  durationdiff(dep_time, arr_time, dep_date, type) {
    var dep_hr = parseInt(dep_time.split(':')[0]);
    var arr_hr = parseInt(arr_time.split(':')[0]);
    if (arr_hr < dep_hr) {
      var nwdate;
      if (!dep_date) {
        nwdate = new Date();
      } else {
        nwdate = new Date(dep_date);
      }
      var newdate = nwdate.setDate(nwdate.getDate() + 1);
      if (type == 'oneway') {
        this.return_d_date = this.datePipe.transform(newdate, 'yyyy-MM-dd');
      } else {
        this.rreturn_d_date = this.datePipe.transform(newdate, 'yyyy-MM-dd');
      }

    } else {
      this.return_d_date = dep_date;
      this.rreturn_d_date = dep_date;
    }
    //console.log("hr :: ",this.return_d_date); 
    var start_date = dep_date + " " + dep_time;
    var end_date = this.return_d_date + " " + arr_time;
    //console.log("start date : ",start_date,"end date :: ",end_date);
    return this.getDataDiff(new Date(start_date.toString()), new Date(end_date.toString()));

  }

  getDataDiff(s_date, e_date) {
    var diff = e_date.getTime() - s_date.getTime();
    var days = Math.floor(diff / (60 * 60 * 24 * 1000));
    var hours = Math.floor(diff / (60 * 60 * 1000)) - (days * 24);
    var minutes = Math.floor(diff / (60 * 1000)) - ((days * 24 * 60) + (hours * 60));
    var seconds = Math.floor(diff / 1000) - ((days * 24 * 60 * 60) + (hours * 60 * 60) + (minutes * 60));
    return hours + " hrs " + ": " + minutes + " min";
    // return { day: days, hour: hours, minute: minutes, second: seconds };
  }
  checked = false;

  renderdata() {

    this.search_details = this.searchForm.value;
    setTimeout(() => {
      if(this.flightdetails != '') {
        this.flightdetails.forEach(element => {

          var obj = element;

          if (element.inventory_user_Type == 'Patner') {
            element.total_payable_price_with_travellers = element.total_payable_price * this.search_details.travellers
            // console.log("element=========>>>",element.total_payable_price_with_travellers);
          }
          if (element.inventory_user_Type == 'fareboutique' && element.add_margin == 1) {
            // console.log('sunil===========',obj);
            this.newCal = element.vendorAmountLimit < element.total_payable_price;
            this.newCal2 = element.vendorAmountLimit > element.per_ticket_avg_price + element.margin - element.discount;
            this.newCal3 = ~~(element.vendorAmountLimit / (element.per_ticket_avg_price + element.margin - element.discount));
            //  element.total_payable_price=element.total_payable_price + ((element.margin - element.discount)*formData.travellers)  
            element.total_payable_price = element.total_payable_price + ((element.margin - element.discount) * this.search_details.travellers)
            Object.assign(obj, { newCal: this.newCal, newCal2: this.newCal2, newCal3: this.newCal3 })
          }
        });
      }
    }, 100);
    //console.log("searchde")
    setTimeout(() => {
      if (this.search_details.trip_type == '1') {
        this.flighttype == 'return'
      } else {
        this.flighttype == 'oneway'
        this.disableInput = true;
        this.return_date_input = false;
      }
    }, 1000);
    // this.ngxLoader.stop();   
  }

  setsearchdate(d) {
    this.searchForm.patchValue({
      departure_date: this.datePipe.transform(d, "yyyy-MM-dd")
    });
    // this.searchForm.value.departure_date = this.datePipe.transform(d, "yyyy-MM-dd");
    // console.log("seaer",this.datePipe.transform(d,"yyyy-MM-dd"))
    // this.searchdate = this.datePipe.transform(d, "yyyy-MM-dd")
    this.searchsubmit();
  }


  contactSupport() {
    $('#offlineModle').modal('show');
  }

  searchsubmit() {

    const self = this;
    this.submitted = true;
    let formdata = this.searchForm.value;
    // console.log("dasdadwwadawd",this.searchForm.value)
    if (formdata.departure_city_id != "" && formdata.departure_airport_id != '' && formdata.arrival_city_id != "" && formdata.arrival_airport_id != '') {

      var sendrequestdata = {
        departure_city_id: formdata.departure_city_id,
        departure_airport_id: formdata.departure_airport_id,
        arrival_city_id: formdata.arrival_city_id,
        arrival_airport_id: formdata.arrival_airport_id,
        trip_type: formdata.trip_type,
        departure_date: this.searchdate != '' ? this.searchdate : formdata.departure_date,
        // departure_date: formdata.departure_date,
        return_date: formdata.return_date,
        adult: formdata.adult,
        children: formdata.child,
        infant: formdata.infant
      }
      sessionStorage.setItem("search_form", JSON.stringify(this.searchForm.value));
      this.to_day = this.searchForm.value.departure_date;
      var findindex = this.d_date.findIndex(e => e.flight_date == this.to_day);

      if (this.d_date[findindex - 1] == undefined) {
        this.previous_day = '';
      } else {
        this.previous_day = this.d_date[findindex - 1]
      }
      if (this.d_date[findindex + 1] == undefined) {
        this.next_day = '';
      } else {
        this.next_day = this.d_date[findindex + 1]
      }


      // this.to_day=this.searchForm.value.departure_date;
      // var d = new Date(this.searchForm.value.departure_date);
      // d.setDate(d.getDate() - 1);
      // var predate=new Date(d)
      // var foundpre=this.d_date.find(e => e.flight_date == this.datePipe.transform(predate,"yyyy-MM-dd"));
      // // console.log("preasdasdsdasdsd",this.d_date,foundpre)

      // if(foundpre){
      //   this.previous_day=predate;
      // }else{
      //   this.previous_day=''
      // }

      // var n = new Date(this.searchForm.value.departure_date);
      // n.setDate(n.getDate() + 1);
      // var nextdate=new Date(n)
      // var foundnext=this.d_date.find(e => e.flight_date == this.datePipe.transform(nextdate,"yyyy-MM-dd"));
      // // console.log("preasdasdsdasdsd",this.d_date,foundnext)

      // if(foundnext){
      //   this.next_day=nextdate;
      // }else{
      //   this.next_day=''
      // }


      //this.dataservice.searchflightdetail(sendrequestdata);


      this.httpClient.post('v1/search_flight/search_ticket', sendrequestdata).subscribe((res: any) => {
        // if (res.replyCode == "success" && res.data!= '' ) {
        if (res.replyCode == "success") {
          this.flightdetails = res.data;
          this.ticketDtl = res.ticketDisplaySetting;
          this.imagesrc = res.imgBasePath;
          this.searchresult = true;
          this.renderdata();
          
          if ('ticketDisplaySetting' in res) {
            if (res.ticketDisplaySetting.ticket_display_status == 1 && res.data.some((val) => val.inventory_user_Type == 'self')) {
              this.showSelf = true;
            } else {
              this.showSelf = false;
            }
          } else {
            this.flightdetails = '';
          }



          // }else{
          //     this.flightdetails='';
          //     this.imagesrc='';
          //     sessionStorage.removeItem("flights");
          //     sessionStorage.removeItem("imagepath");
          //     self.toastr.errorToastr("Result Not Found","Error")
        }
      }, err => {
        this.flightdetails = '';
        this.imagesrc = '';
        this.toastr.errorToastr(err.error.replyMsg, "Error");
      });

      this.httpClient.post('v1/block_search_flight/search_ticket', sendrequestdata).subscribe((res: any) => {
        // if (res.replyCode == "success" && res.data!= '' ) {
        if (res.replyCode == "success") {
          this.flightBlockedDetails = res.data;
          this.imagesrc = res.imgBasePath;
          this.searchresult = true;
          this.renderdata()

          if ('ticketDisplaySetting' in res) {
            if (res.ticketDisplaySetting.ticket_display_status == 1 && res.data.length > 0) {
              this.showSelfBlock = true;
            } else {
              this.showSelfBlock = false;
            }
          } else {
            this.showSelfBlock = false;
          }



          // }else{
          //     this.flightBlockedDetails='';
          //     this.imagesrc='';
          //     sessionStorage.removeItem("flights");
          //     sessionStorage.removeItem("imagepath");
          //     self.toastr.errorToastr("Result Not Found","Error")

        }
      }, err => {
        this.flightdetails = '';
        this.imagesrc = '';
        this.toastr.errorToastr(err.error.replyMsg, "Error");
      });

      // this.httpClient.post('v1/search_flight/search_ticket',sendrequestdata).subscribe((res)=> {
      // self.httpClient.loadingShow = false;
      // if(res.replyCode == "error") {
      //   this.searchnotresult=true;
      //  }
      // }); 
    }
    else {
      this.toastr.errorToastr('Fill The Form Correctly.');
    }
  }



  departure_city_list() {
    const self = this;
    //this.httpClient.auth_token = this.user.jwt;
    self.httpClient.loadingShow = true;
    //self.regForm.patchValue({token:this.token});
    var sendrequestdata = {
      flight_type: this.flighttype
      // flight_type:"one_way"
    }
    this.departure_city_data = [];
    this.d_city = [];
    this.httpClient.post('v1/search_flight/departure_city_list', sendrequestdata).subscribe((res) => {
      self.httpClient.loadingShow = false;
      if (res.replyCode == "success") {

        this.departure_city_lists = res.data;
        // var d_city=[];
        self.departure_city_lists.forEach(element => {
          this.d_city.push({
            id: element.departure_city_id,
            text: element.departure_city_name,
            additional: {
              image: element.airport_code,
              winner: element.airport_name,
              airportId: element.airport_id
            }
          })
        });
        console.log("departureCityArray Unblocked::::::::::::::::::::::: ", this.departure_city_lists);
        // this.departure_city_data.sort((a,b) => a.text.localeCompare(b.text));

        // this.departure_city_data= this.d_city.filter((item, i, ar) => ar.indexOf(item) === i).sort((a,b) => a.text.localeCompare(b.text));
        // this.options = {
        //   templateResult: this.templateResult,
        //   templateSelection: this.templateSelection
        // };
      }

      console.log('d_city length ::::::::::::: ', this.d_city);
      console.log('res.data length ::::::::::::: ', res.data);


      if (this.d_city.length === res.data.length) {
        this.httpClient.post('v1/block_search_flight/departure_city_list', sendrequestdata).subscribe((res) => {
          self.httpClient.loadingShow = false;
          if (res.replyCode == "success") {
            this.departureBlockList = res.data;

            self.departureBlockList.forEach(element => {
              if (!this.d_city.some((val) => val.id === element.departure_city_id)) {
                this.d_city.push({
                  id: element.departure_city_id,
                  text: element.departure_city_name,
                  additional: {
                    image: element.airport_code,
                    winner: element.airport_name,
                    airportId: element.airport_id
                  }
                });
              }
            });
            console.log("departureCityArray Blocked ::::::::::::::::::::::: ", this.departureBlockList);

            this.departure_city_data = this.d_city;
          }

          // (arr) => this.d_city.keys(this.d_city.assign({},  arr.map(a=>({[a]:true}))));
          // console.log("BlockListArry",this.b_city);

          // this.dep_city_block=this.b_city;
          this.options = {
            templateResult: this.templateResult,
            templateSelection: this.templateSelection
          };

        },err=>{
          this.toastr.errorToastr(err.error.message,"Error")
        });
      }





    });
  }

  // departure_city_block(){
  //       const self=this;
  //       //this.httpClient.auth_token = this.user.jwt;
  //       self.httpClient.loadingShow = true;
  //       //self.regForm.patchValue({token:this.token});
  //       var sendrequestdata={
  //       flight_type:this.flighttype
  //       // flight_type:"one_way"
  //   }
  //     this.httpClient.post('v1/block_search_flight/departure_city_list',sendrequestdata).subscribe((res)=> {
  //     self.httpClient.loadingShow = false;
  //       if(res.replyCode == "success") { 
  //         this.departureBlockList = res.data;

  //         self.departureBlockList.forEach(element => {
  //           this.b_city.push({
  //             id:element.departure_city_id,
  //             text:element.departure_city_name,
  //             additional: {
  //               image: element.airport_code,
  //               winner: element.airport_name
  //             }
  //           }) 
  //         });
  //         // console.log("BlockListArry",this.b_city);

  //         // this.dep_city_block=this.b_city;
  //         // this.options = {
  //         //   templateResult: this.templateResult,
  //         //   templateSelection: this.templateSelection
  //         // };
  //         }
  //     });
  // }




  public templateResult = (state: Select2OptionData): JQuery | string => {
    if (!state.id) {
      return state.text;
    }
    return jQuery('<span style="font-weight:500">' + state.text + ' </span><p style="margin:0px;font-size:10px;position:relative"><span style="font-size:12px;float:right;right:3px;margin-top:-20px;font-weight:500;position:absolute;">' + state.additional.image + '</span></p>');
    // return jQuery('<span style="font-weight:500">' + state.text + ' </span><p style="margin:0px;font-size:10px;position:relative">' + state.additional.winner + '<span style="font-size:12px;float:right;right:3px;margin-top:-12px;font-weight:500;position:absolute;">'+state.additional.image+'</span></p>');
  }
  // function for selection template
  public templateSelection = (state: Select2OptionData): JQuery | string => {
    if (!state.id) {
      return state.text;
    }
    // return jQuery('<span>'+ state.text +'&nbsp;'+ state.additional.winner + '&nbsp; ('+state.additional.image +')</span>');
    return jQuery('<span>' + state.text + '&nbsp; (' + state.additional.image + ')</span>');
  }


  arrival_city_list(id) {
    if (id) {
      this.a_city = [];
      var filterlist = this.departure_city_data.filter(x => x.id == id);
      this.departure_city_ids = filterlist[0].id;
      this.searchForm.patchValue({ departure_city_id: filterlist[0].id, departure_airport_id: filterlist[0].additional.airportId })
      const self = this;
      self.httpClient.loadingShow = true;
      var sendrequestdata = {
        flight_type: this.flighttype,
        departure_city_id: this.departure_city_ids
      }
      this.httpClient.post('v1/search_flight/arrival_city_list', sendrequestdata).subscribe((res) => {
        self.httpClient.loadingShow = false;
        if (res.replyCode == "success") {
          self.arrival_city_lists = res.data;
          this.Arrivalcity_DATA.length = 0;

          self.arrival_city_lists.forEach(element => {
            this.a_city.push({
              id: element.arrival_city_id,
              text: element.arrival_city_name,
              additional: {
                image: element.airport_code,
                winner: element.airport_name,
                airportId: element.airport_id
              }
            })
          });
          console.log("arrival_city_lists Unblocked ::::::::::::::::::::::: ", this.arrival_city_lists);

          // this.arrival_city_data=this.a_city;
          // this.arrival_city_data= this.a_city.filter((item, i, ar) => ar.indexOf(item) === i).sort((a,b) => a.text.localeCompare(b.text));
          // this.options1 = {
          //   templateResult: this.templateResult,
          //   templateSelection: this.templateSelection
          // };

          if (this.a_city.length === this.arrival_city_lists.length) {
            this.httpClient.post('v1/block_search_flight/arrival_city_list', sendrequestdata).subscribe((res) => {
              self.httpClient.loadingShow = false;

              if (res.replyCode == "success") {
                self.arrival_city_lists = res.data;
                this.Arrivalcity_DATA.length = 0;
                // var a_city=[]

                self.arrival_city_lists.forEach(element => {
                  if (!this.a_city.some((val) => val.id === element.arrival_city_id)) {
                    this.a_city.push({
                      id: element.arrival_city_id,
                      text: element.arrival_city_name,
                      additional: {
                        image: element.airport_code,
                        winner: element.airport_name,
                        airportId: element.airport_id
                      }
                    })
                  }
                });
                console.log("arrival_city_lists Blocked ::::::::::::::::::::::: ", this.arrival_city_lists);

                this.arrival_city_data = this.a_city;
                // this.arrival_city_data= this.a_city.filter((item, i, ar) => ar.indexOf(item) === i).sort((a,b) => a.text.localeCompare(b.text));
                this.options1 = {
                  templateResult: this.templateResult,
                  templateSelection: this.templateSelection
                };

                // this.arrival_city_data=a_city;
                // this.options1 = {
                //   templateResult: this.templateResult,
                //   templateSelection: this.templateSelection
                //   };
              }
            });

          }


        }
      });

    }
  }
  // arrival_city_block(id){
  //  if(id){ 
  //   var filterlist = this.departure_city_lists.filter(x => x.departure_city_id == id);
  //   this.departure_city_ids=filterlist[0].departure_city_id;
  //   this.searchForm.patchValue({departure_city_id:filterlist[0].departure_city_id,departure_airport_id:filterlist[0].airport_id})
  //   const self=this;
  //   self.httpClient.loadingShow = true;
  //   var sendrequestdata={
  //     flight_type:this.flighttype,
  //     departure_city_id:this.departure_city_ids
  //   }  
  //   this.httpClient.post('v1/block_search_flight/arrival_city_list',sendrequestdata).subscribe((res)=> {
  //   self.httpClient.loadingShow = false;
  //   if(res.replyCode == "success") { 
  //       self.arrival_city_lists = res.data;
  //       this.Arrivalcity_DATA.length=0;
  //       var a_city=[]
  //       self.arrival_city_lists.forEach(element => {
  //             a_city.push({
  //               id:element.arrival_city_id,
  //                 text:element.arrival_city_name,
  //                 additional: {
  //                   image: element.airport_code,
  //                   winner: element.airport_name
  //                 }
  //             })
  //       });
  //       // this.arrival_city_data=a_city;
  //       // this.options1 = {
  //       //   templateResult: this.templateResult,
  //       //   templateSelection: this.templateSelection
  //       //   };
  //       }
  //   });
  //   }
  // }

  // arrival_cityid(id){
  //   if(id){
  //   //var name=id.split('-')[0];
  //   var filterlist = this.arrival_city_lists.filter(x => x.arrival_city_id == id);
  //   
  //console.log("filter city :::: ",filterlist);
  //   this.arrival_city_ids=filterlist[0].arrival_city_id;
  //   this.searchForm.patchValue({arrival_city_id:filterlist[0].arrival_city_id,arrival_airport_id:filterlist[0].airport_id})
  //   const self=this;
  //   //this.httpClient.auth_token = this.user.jwt;
  //   self.httpClient.loadingShow = true;
  //   //self.regForm.patchValue({token:this.token});
  //   var is_rtn;
  //   if(this.flighttype == 'return'){
  //     is_rtn=1;
  //   }else{
  //     is_rtn=0;
  //   }
  //   var sendrequestdata={
  //     departure_city_id:this.departure_city_ids,
  //     arrival_city_id:this.arrival_city_ids,
  //     is_return:is_rtn
  //   }
  //   this.httpClient.post('v1/search_flight/booking_avaliable_date_list',sendrequestdata).subscribe((res)=> {
  //   self.httpClient.loadingShow = false;
  //   if(res.replyCode == "success") { 
  //       self.d_date = res.data;
  //       
  //console.log('arrival city list', self.d_date);
  //       var date_length=res.data.length;
  //       
  //console.log("array length ::::",date_length)
  //      // this.minDate=self.datePipe.transform(new Date(), 'yyyy-MM-dd')
  //      if(date_length > 0){
  //       this.minDate=self.d_date[0].flight_date
  //       this.maxDate=self.d_date[date_length-1].flight_date
  //       }
  //     }
  //   });
  //  }
  // }

  arrival_cityid(id) {
    if (id) {
      var filterlist = this.arrival_city_data.filter(x => x.id == id);
      if (filterlist) {
        console.log('filter list ::::::::::::::::: ', filterlist);

        this.arrival_city_ids = filterlist[0].id;
        this.searchForm.patchValue({ arrival_city_id: filterlist[0].id, arrival_airport_id: filterlist[0].additional.airportId })
        const self = this;
        self.httpClient.loadingShow = true;
        var is_rtn;
        if (this.flighttype == 'return') {
          is_rtn = 1;
        } else {
          is_rtn = 0;
        }
        var sendrequestdata = {
          departure_city_id: this.departure_city_ids,
          arrival_city_id: this.arrival_city_ids,
          is_return: is_rtn
        }
        var validdates = []
        this.httpClient.post('v1/search_flight/booking_avaliable_date_list', sendrequestdata).subscribe((res) => {
          self.httpClient.loadingShow = false;


          if (res.replyCode == "success") {
            self.d_date = res.data;
            var normal_dates = [];
            var date_length = res.data.length;
            // this.minDate=self.datePipe.transform(new Date(), 'yyyy-MM-dd')
            if (date_length > 0) {
              // this.minDate=self.d_date[0].flight_date
              // this.maxDate=self.d_date[date_length-1].flight_date

              res.data.forEach(element => {
                validdates.push(new Date(this.datePipe.transform(element.flight_date, 'MM/dd/yyyy')))
                normal_dates.push(element.flight_date);
              });
            }

            // this.myFilter1 = (d: Date): boolean => {
            //   const time=d.getTime();
            //   return validdates.find(x=>x.getTime()==time);
            // } 



            if (date_length == validdates.length) {
              this.httpClient.post('v1/block_search_flight/booking_avaliable_date_list', sendrequestdata).subscribe((res) => {
                self.httpClient.loadingShow = false;
                if (res.replyCode == "success") {
                  // self.d_date = res.data;

                  var date_length = res.data.length;
                  if (date_length > 0) {
                    // this.minDate=self.d_date[0].flight_date
                    // this.maxDate=self.d_date[date_length-1].flight_date

                    res.data.forEach(element => {

                      if (!normal_dates.some((val) => val == element.ticket_date)) {
                        validdates.push(new Date(this.datePipe.transform(element.ticket_date, 'MM/dd/yyyy')));
                        this.d_date.push({
                          'flight_date': element.ticket_date});
                      }
                    });
                  }

                  validdates.sort((a, b) => {
                    return <any>new Date(a) - <any>new Date(b);
                  });

                  this.d_date.sort((a, b) => {
                    return <any>new Date(a.flight_date) - <any>new Date(b.flight_date);
                  });



                  this.myFilter1 = (d: Date): boolean => {
                    const time = d.getTime();
                    return validdates.find(x => x.getTime() == time);
                  }

                  
                }
              });
            }

            if (this.d_date > 0) {
              this.minDate = self.d_date[0].flight_date
              this.maxDate = self.d_date[date_length - 1].flight_date
            }
            this.date=new Date((new Date(self.d_date[0].flight_date).getTime())); 
            console.log("dates DDDDD :::::::",this.d_date)
          }
        });
      }
    }
  }

  changestatus(label) {
    if (label == 'arrivaldate_input') {
      this.submitted = false;
      this.searchForm.patchValue({ departure_city_id: '', departure_date: '', return_date: '' });
      this.arrivaldate_input = true
    } else if (label == 'departure_date') {
      this.submitted = false;
      this.searchForm.patchValue({ departure_date: '', return_date: '' });
      this.departure_date = true
    } else if (label == 'return_date_input') {
      this.submitted = false;

      //console.log("return date")
      this.return_date_input = true
    } else if (label == 'traveller_input') {
      this.submitted = false;
      this.traveller_input = true
    }
  }

  set_d_date(val) {
    this.searchForm.patchValue({ return_date: '' });

    //console.log("todays date",this.datePipe.transform(val, 'yyyy/MM/dd'));
    var currentdate = this.datePipe.transform(val, 'yyyy-MM-dd');
    this.searchForm.patchValue({ departure_date: this.datePipe.transform(val, 'yyyy-MM-dd') });
    if (this.flighttype == 'return') {
      var sendrequestdata = {
        departure_city_id: this.departure_city_ids,
        arrival_city_id: this.arrival_city_ids,
        departure_date:this.datePipe.transform(val, 'yyyy-MM-dd'),
        is_return: 1
      }
      var validdates = []
      this.httpClient.post('v1/search_flight/return_booking_avaliable_date_list', sendrequestdata).subscribe((res) => {
        this.httpClient.loadingShow = false;
        if (res.replyCode == "success") {

          res.data.forEach(element => {
            var nwdate = new Date(currentdate);
            var newdate = nwdate.setDate(nwdate.getDate() + element.return_in_days);
            var return_d_date = this.datePipe.transform(newdate, 'MM/dd/yyyy');
            //var convdate=return_d_date.toString()+"T08:00:00"
            validdates.push(new Date(return_d_date))
          });
          console.log("return date list Unblocked ::::::::::::::::::::::: ", res.data);


          //console.log("return date :::::::",validdates)
          // this.validDates=validdates[0]
          // this.myFilter = (d: Date): boolean => {
          //   const time=d.getTime();
          //   return validdates.find(x=>x.getTime()==time);
          // }




          if (validdates.length == res.data.length) {

            this.httpClient.post('v1/block_search_flight/return_booking_avaliable_date_list', sendrequestdata).subscribe((res) => {
              this.httpClient.loadingShow = false;
              if (res.replyCode == "success") {

                res.data.forEach(element => {
                  var nwdate = new Date(currentdate);
                  var newdate = nwdate.setDate(nwdate.getDate() + element.return_in_days);
                  var return_d_date = this.datePipe.transform(newdate, 'MM/dd/yyyy');
                  //var convdate=return_d_date.toString()+"T08:00:00"

                  if (!validdates.some((val) => val == return_d_date)) {
                    validdates.push(new Date(return_d_date))
                  }

                });


                validdates.sort((a, b) => {
                  return <any>new Date(a) - <any>new Date(b);
                });
                var date_length=validdates.length;
                if(date_length > 0){
                  console.log("date length > 0")
                  this.return_min_date=this.datePipe.transform(validdates[0], 'yyyy-MM-dd');
                  this.return_max_date=this.datePipe.transform(validdates[date_length-1], 'yyyy-MM-dd');   
                  this.returndate_min=new Date((new Date(validdates[0]).getTime()));

                }
                console.log("return date list Blocked ::::::::::::::::::::::: ", res.data);

                // this.validDates=validdates[0]
                this.myFilter = (d: Date): boolean => {
                  const time = d.getTime();
                  return validdates.find(x => x.getTime() == time);
                }
              }
            });
          }
        }
      });
    }


    // var nwdate =  new Date(newReturnTodate);
    // var newdate= nwdate.setDate(nwdate.getDate()+this.ReturnfirstFormGroup.value.trip_duration);
    // var return_d_date=this.datePipe.transform(newdate, 'yyyy-MM-dd');
  }

  set_r_date(val) {
    //console.log("return date",this.datePipe.transform(val, 'yyyy/MM/dd'));
    this.searchForm.patchValue({ return_date: this.datePipe.transform(val, 'yyyy-MM-dd') });
  }

  // 

  Change_Status(val) {
    this.initiate()
    if (val == 'oneway') {
      this.disableInput = true;
      this.return_date = '';
      this.flighttype = 'one_way';
      this.searchForm.patchValue({
        trip_type: '0',
        adult: 1,
        child: 0,
        travellers: 1,
        infant: 0
      });
      this.return_date_input = false;
      this.departure_city_list();
    } else {
      this.flighttype = 'return';
      this.disableInput = null;
      this.searchForm.patchValue({
        trip_type: '1',
        adult: 1,
        child: 0,
        travellers: 1,
        infant: 0
      });
      this.departure_city_list();
    }

    //console.log("flight type",this.searchForm.value);
  }

  open_popup() {
    $('#totalpassengers').modal('show');
  }

  traveller() {
    var adult = this.travellers.value.adult;
    var child = this.travellers.value.child;
    var infant = this.travellers.value.infant;

    if (adult != '' && child != '' && infant != '') {
      if (parseInt(adult) >= parseInt(infant)) {
        this.total = parseInt(adult) + parseInt(child) + parseInt(infant);

        this.searchForm.patchValue({
          adult: parseInt(adult),
          child: parseInt(child),
          infant: parseInt(infant)
        });

        //console.log("formdata ::", this.searchForm);
        $('#totalpassengers').modal('hide');
        this.searchForm.patchValue({ travellers: this.total });
      } else {
        alert('Please select infant less than adult');
      }

    } else {
      alert('All Field is required');
    }
  }

  searchData() {
    console.log('search for ::::::::::::::::::::: ', this.searchForm);

    alert(JSON.stringify(this.searchForm.value));
    //var type = this.searchForm.value.type;
    const self = this;
    //this.httpClient.auth_token = this.user.jwt;
    self.httpClient.loadingShow = true;
    //self.regForm.patchValue({token:this.token});

    this.httpClient.post('searchFlights', this.searchForm.value).subscribe((res) => {
      self.httpClient.loadingShow = false;
      console.log('success check ::::::::::::::::::::::::::: ', res);

      if (res.success) {
        localStorage.setItem("user_emailAgency", JSON.stringify(res.data));
        localStorage.setItem("type", 'register');
        self.flight = res.data;

        //console.log('flight Data', self.flight);
        $('#success').show();
        setTimeout(function () {
          $('#success').hide();
        }, 2000);
      } else {
        $('#error').show();
        setTimeout(function () {
          $('#error').hide();
        }, 4000);
      }
    });
  }


  ngAfterViewInit() {
    this.setInitialValue();
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();

    this._onDestroy1.next();
    this._onDestroy1.complete();
  }

  protected setInitialValue() {
    this.filtered_departurecity
      .pipe(take(1), takeUntil(this._onDestroy))
      .subscribe(() => {
        this.departureSelect.compareWith = (a: departurecity_lists, b: departurecity_lists) => a && b && a.id === b.id;
      });
  }

  protected filterDeparturecity() {
    if (!this.Departurecity_DATA) {
      return;
    }
    // get the search keyword
    let search = this.departureFilterCtrl.value;
    if (!search) {
      this.filtered_departurecity.next(this.Departurecity_DATA.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    this.filtered_departurecity.next(
      this.Departurecity_DATA.filter(a => a.departure_city_name.toLowerCase().indexOf(search) > -1)
    );

  }

  protected filterarrivalcity() {
    if (!this.Arrivalcity_DATA) {
      return;
    }
    // get the search keyword
    let search = this.arrivalFilterCtrl.value;
    if (!search) {
      this.filtered_arrivalcity.next(this.Arrivalcity_DATA.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    this.filtered_arrivalcity.next(
      this.Arrivalcity_DATA.filter(d_city => d_city.arrival_city_name.toLowerCase().indexOf(search) > -1)
    );
  }
}

/// EMPTY   PLEASE CHCEK
export interface JQuery {
}

export interface arrivalcity_lists {
  id: number;
  arrival_city_id: number;
  arrival_city_name: string;
  arrival_city_code: number;
  airport_name: string;
  airport_id: number;
}
export interface departurecity_lists {
  id: number;
  departure_city_id: number;
  departure_city_name: string;
  departure_city_code: number;
  airport_name: string;
  airport_id: number;

}

